.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 85%;
  align-items: center;
  overflow-y: hidden; 
}

.stack {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 70%;
}

.stackItem {
  display: flex;
  height: 100%;
  width: 70%;
  margin-right: 5px;
  flex-direction: row-reverse;
  flex-grow: 1;
  align-self: stretch;
  flex-shrink: 0;
}

.chatRoot {
  flex: 1;
  display: flex;
}

.chatContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
}

.chatMessageStream {
  flex-grow: 1;
  max-width: 1028px;
  width: 100%;
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.chatMessageGptMinWidth {
  max-width: 500px;
  margin-bottom: 20px;
}

.chatInputBox {
  position: sticky;
  bottom: 0;
  /* flex: 0 0 100px; */
  padding-top: 12px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1028px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.chatInput {
  width: 100%;
  flex: 1;
  border: none;
  height: 2.5rem;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
  margin: 5px;
}

.chatInput:focus {
  outline: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
}

.sendBtn {
  padding: 12px 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  transition: .3s ;
}
.sendBtn:hover {
transform: scale(1.05);
background-color:rgba(255, 224, 224, 1);




}
.clearChat{
  height: 2.5rem;
  background-color: #fd8d8d;
  border:none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 6px 5px;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.bot-message {
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
}


.user-message {
  justify-content: flex-end;
}

.message-content {
  padding: 15px;
  border-radius: 5px;
  max-width: 80%;
  word-wrap: break-word;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  .message-content span{
    font-size: medium; /* Set font size to medium */
    line-height: 1.5; /* Adjust line height for better readability */
    display: block; /* Make the span behave like a block element (optional) */
  }

.bot-message .message-content {
  background: #e0f7fa;
}

.user-message .message-content {
  background: #ffe0e0;
}

.error-text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px; 
  font-weight: 400; 
  color: red; 
  margin: 0; 
  padding: 0; 
  line-height: 1.5; 
}

/* progress bar */
.progress-bar {
  width: 80%;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  height: 15px;
}
.progress {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #3498db, #f8efff, #883ea8, #3498db);
  background-size: 300% 100%;
  animation: gradientMove 4s infinite linear;
}

.answerText {
  padding: 20px;
  background: rgb(249, 249, 249);
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  padding-top: 16px;
  padding-bottom: 16px;
  white-space: pre-line;
  max-width: 60%;
  display: flex;
  min-width: 500px;
  margin-left: 5%;
}

/* Keyframes for moving the gradient */
@keyframes gradientMove {
  0% {
    background-position: 0% 0%; /* Start the gradient at the left */
  }
  50% {
    background-position: 100% 0%; /* Move to the right */
  }
  100% {
    background-position: 0% 0%; /* Return the gradient to the left */
  }

}
