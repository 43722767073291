.feedback-container {
    margin-left: 5%;
    min-width: 600px;
    max-width: 60%;
    display: flex;
    padding: 8px 10px;
    justify-content: flex-start;
    flex-direction: column;
}

.response-feedback {
    display: flex;
    align-items: center;
    gap: .5rem;
}
.feedback-subsection-container{
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    gap: 1rem;
}

.response-feedback-label, 
.feedback-subsection-label {
    font-size: .8rem;
    color: #333;
    
}

.response-feedback-icons, 
.feedback-subsection-icons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.feedback-icon {
    cursor: pointer;
    transition: transform 0.1s ease-in-out; 
}

.feedback-icon:hover {
    transform: scale(1.2);
}

.detailed-feedback-container {
    margin-top: 1rem;
    padding: 10px;
    border: 1px solid;

}

.feedback-subsection-container {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
}

.feedback-subsection {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}
.feedbackInput {
    width: 100%;
    border-radius: 5px;
    height: 5rem;
    border: 1px solid #ccc;
    resize: none;
  }
  .feedBackButtonSubmit {
	background-color: #0072CE;
	border-color: #0072CE;
	border-radius: 0px;
	font-size: 13px;
	line-height: 1.42857143;
	padding: 6px 12px;
	margin-right: 2px;
	font-weight: normal;
	text-align: left;
	vertical-align: middle;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	color: #fff;
	width: 80px;
	display: inline-block;
	height: 20px;
}  

.feedback-footer-section{
    display: flex;
    flex-direction: row;
    padding: .5rem 1rem;
    justify-content: space-between;
    align-items: center;
}